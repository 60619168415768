<template>
    <div style="background-color: #fff">
        <div class="pt-3">
            <search @condition="condition" :fields="searchFields">
                <el-button type="primary" v-access="{url:baseUrl,method:'POST'}" @click="addType" size="medium"
                           slot="add" icon="el-icon-plus">添加分类
                </el-button>
            </search>
        </div>
        <div class="mt-3" id="table-area">
            <my-table row-key="id" :sort-change="sortChange" :sort="page.sort" :fields="showFields" :data="tableData">
                <el-table-column
                    slot="operation"
                    width="90"
                    label="操作"
                >
                    <template slot-scope="scope">
                        <el-button
                            class="rank"
                            size="mini"
                            :data-id="scope.row.id"
                            circle
                            type="default"
                            icon="el-icon-rank"
                        >
                        </el-button>
                        <el-button
                            size="mini"
                            circle
                            type="primary"
                            icon="el-icon-edit"
                            @click="editType(scope.$index)">
                        </el-button>
                    </template>
                </el-table-column>
            </my-table>
        </div>
        <div class="pb-3 mt-3">
            <el-pagination
                @size-change="sizeChange"
                @current-change="pageChange"
                :current-page="page.pageNumber"
                :page-sizes="[10, 15, 30, 50,100]"
                :page-size="page.pageSize"
                :background="true"
                layout="sizes,prev, pager,next,total"
                :total="page.total">
            </el-pagination>
        </div>
        <form-dialog ref="formDialog" @closeFormDialog="submit" :title="form.title" :show="form.show"
                     :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
    </div>
</template>
<script>
import F from '@/fields/fields';
import search from "@/components/common/search";
import Sortable from 'sortablejs';

export default {
    name: "type2d",
    components: {
        search
    },
    data: function () {
        return {
            baseUrl: '/big/offline/type',
            page: {
                search: {},
                // sort: {prop: 'create_time', order: 'descending'},
                sort: {},
                total: 1,
                pageSize: 100,
                pageNumber: 1,
            },
            tableData: [],
            actionName: '',
            form: {
                action_name: '',
                show: false,
                title: '',
                data: {},
                fields: [],
                rules: {},
            }
        }
    },
    computed: {
        searchFields: function () {
            return F.search('offlineList2dType', 'search');
        },
        showFields: function () {
            return F.show('offlineList2dType', 'show');
        }
    },
    mounted() {
        let dom = document.getElementById('table-area').getElementsByTagName('tbody')[0];
        new Sortable(dom, {
            handle: '.rank',
            onEnd: () => {
                let data = {type: 'order', data: {}};
                [...dom.getElementsByClassName('rank')].map((v, index) => {
                    data.data[v.dataset.id] = index;
                });
                this.$com.put(this.baseUrl, data).then(() => {
                    this.$message.success('保存成功!');
                });
            }
        });
    },
    methods: {
        condition: function (search) {
            this.page.search = search;
            this.page.pageNumber = 1;
            this.search();
        },
        search: function () {
            this.$com.get(this.baseUrl, {
                params: this.page,
            }).then(data => {
                this.page.total = data.data.total;
                this.page.pageNumber = data.data.pageNumber;
                this.page.pageSize = data.data.pageSize;
                this.tableData = data.data.data;
            });
        },
        submit: function (data) {
            if (data.type === 'cancel') {
                this.form.show = false;
                return;
            }
            switch (this.actionName) {
                case 'editType':
                    this.$com.put(this.baseUrl, data.data).then(userData => {
                        this.form.show = false;
                        this.$message.success('保存成功!');
                        this.tableData.splice(this.form.index, 1, userData.data);
                    });
                    break;
                case 'addType':
                    this.$com.post(this.baseUrl, data.data).then((data) => {
                        this.$message.success('添加成功!');
                        this.form.show = false;
                        this.tableData.unshift(data.data);
                    });
                    break;
            }
        },
        editType: function (index) {
            this.actionName = 'editType';
            let form = F.get('offlineList2dType', 'edit', this.tableData[index]);
            form.index = index;
            this.form = form;
        },
        addType: function () {
            this.actionName = 'addType';
            this.form = F.get('offlineList2dType', 'add', {status: 1});
        },
        sortChange: function (column) {
            if (!column.order) return;
            this.page.sort = {prop: column.prop, order: column.order};
            this.search();
        },
        sizeChange: function (size) {
            this.page.pageSize = size;
            this.search();
        },
        pageChange: function (page) {
            this.page.pageNumber = page;
            this.search();
        },
    }
}
</script>
<style scoped>

</style>
